<template>
  <div :style="'height:' + innerHeight + 'px;'">
    <div class="bg"></div>
    <div class="e">
      <Card
        style="width: 500px; border: 1px solid #eeeeee"
        shadow
        bordered
        title="登录"
      >
        <div style="margin: 20px 30px">
          <Form ref="formInline" :model="formInline" :rules="ruleInline">
            <FormItem prop="account">
              <Input
                type="text"
                v-model="formInline.account"
                placeholder="请输入账户"
              >
                <Icon size="18" type="ios-person-outline" slot="prepend"></Icon>
              </Input>
            </FormItem>
            <FormItem prop="password">
              <Input
                type="password"
                v-model="formInline.password"
                placeholder="请输入密码"
              >
                <Icon size="18" type="ios-lock-outline" slot="prepend"></Icon>
              </Input>
            </FormItem>
            <!-- <FormItem prop="yzm">
              <Row :gutter="16">
                <Col span="14">
                  <Input
                    type="text"
                    v-model="formInline.yzm"
                    placeholder="请输入验证码"
                  >
                  </Input>
                </Col>
                <Col span="8">
                   <img :src="captcha_img" style="width:100%" @click="getC" />
                </Col>
              </Row>
            </FormItem> -->
            <FormItem>
              <Button
                style="background: #ff761d; color: #fff; border-color: #ff761d"
                type="primary"
                @click="handleSubmit('formInline')"
                >登录</Button
              >
            </FormItem>
          </Form>
        </div>
      </Card>
    </div>

    <!-- <div class="bottom_box">
      <a
        class="bei_text"
        target="_blank"
        href="https://beian.miit.gov.cn/#/Integrated/index"
        >皖ICP备13号-28</a
      >
      <a
        class="jin_bei_text"
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34019102001024"
      >
        <img style="margin-right: 5px" src="../../assets/jh.png" alt="" />
        <div>皖公网安备 34019102001024号</div>
      </a>
      <a
        class="jin_bei_text"
        target="_blank"
        :href="config.apiUrl + '/img/yyzz.jpg'"
      >
        <img
          style="margin-right: 5px; width: 20px; height: 20px"
          src="../../assets/gh.png"
          alt=""
        />
        <div>安徽勺子同学科技有限公司</div>
      </a>
      <a
        class="jin_bei_text"
        target="_blank"
        href="https://beian.miit.gov.cn/#/Integrated/index"
      >
        <img
          style="margin-right: 5px; width: 20px; height: 20px"
          src="../../assets/dp.png"
          alt=""
        />
        <div>经营性 网站备案信息</div>
      </a>
      <a class="jin_bei_text" target="_blank" href="https://www.12315.cn/">
        <div>
          <span style="color: #1a9d3f">12315消费争议</span> 快速解决绿色通道
        </div>
      </a>
      
    </div> -->
  </div>
</template>
<script>
import { login, getCaptcha } from "@/api/login";
import { windowsRise } from "@/utils/dom";
import config from "@/config";
import { dataJump } from "../../api/elseSetting";
import { setCookies } from "../../utils/helper";
import { number } from "echarts";
export default {
  data() {
    return {
      innerHeight: 0,
      captcha_img: "",
      formInline: {
        account: "",
        password: "",
        // yzm:"",
        // key:""
      },
      ruleInline: {
        account: [{ required: true, message: "账户不能为空", trigger: "blur" }],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        // yzm: [
        //   { required: true, message: "验证码不能为空", trigger: "blur" },
        // ],
      },
    };
  },
  created() {
    var _this = this;
    let token = this.$route.query.token;
    if (token) {
      setCookies("hlzw_canyin_token", token);
      setTimeout(() => {
        dataJump()
          .then((res) => {
            this.$Message.success(res.msg);
            setCookies("hlzw_canyin_token", res.data.token);
            this.$store.commit("setUserInfo", res.data.user);
            this.$store.commit("setMenu", res.data.menu);
            this.$store.commit("setPlugin", res.data.plugin);
            this.$store.commit("setActiveIndex", 0);
            this.$store.commit("setStore_mode", res.data.store_model);
            this.$store.commit("setConfg_Pay_url", res.data.pay_url);
            this.$router.push({ path: "/admin" });
          })
          .catch((res) => {
            let data = res === undefined ? {} : res;
            this.$Message.error(data.msg || "登录失败");
          });
      }, 500);
      return;
    }
    top !== window && (top.location.href = location.href);
    document.onkeydown = function () {
      if (_this.$route.name === "登录") {
        let key = window.event.keyCode;
        if (key === 13) {
          _this.handleSubmit("formInline");
        }
      }
    };
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.restHeight();
    windowsRise(window, this.restHeight);
    // this.getC()
    // this.$store.commit('setUserInfo', {id:1});
  },
  methods: {
    // getC(){
    //   getCaptcha().then(res=>{
    //     this.captcha_img = res.data.img
    //     this.formInline.key = res.data.key
    //   }).catch(err=>{
    //     this.$Message.error(err.msg);
    //   })
    // },
    /**修改高度 */
    restHeight() {
      this.innerHeight = window.innerHeight - 2;
    },
    handleSubmit(name) {
      var url = config.apiUrl + "/upload/mp3/2.m4a";
      console.log(url);
      var mp3 = new Audio(url);
      mp3.play();
      this.$refs[name].validate((valid) => {
        if (valid) {
          login(this.formInline)
            .then((res) => {
              console.log("111", res.data);
              localStorage.setItem(
                "printNameForm",
                JSON.stringify(res.data.print_wired_type)
              );
              this.$Message.success(res.msg);
              setCookies("hlzw_canyin_token", res.data.token); //登录token
              this.$store.commit("setUserInfo", res.data.user);
              this.$store.commit("setMenu", res.data.menu);
              this.$store.commit("setPlugin", res.data.plugin);
              this.$store.commit("setActiveIndex", 0);
              this.$store.commit("setStore_mode", res.data.store_model);
              this.$store.commit("setConfg_Pay_url", res.data.pay_url);
              this.$router.push({ path: "/admin" });
            })
            .catch((err) => {
              // this.getC()
              this.$Message.error(err.msg);
            });
        } else {
          this.$Message.error("数据不能为空");
        }
      });
    },
    toLink(e) {
      console.log(e);
      // window.open(e,'_blank')
    },
  },
};
</script>
<style scoped>
.ivu-icon {
  width: 30px;
}
.e {
  margin-top: -20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
}
.bg {
  background: url("../../assets/14.jpg") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.bottom_box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #0a1622;
  height: 60px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
a:hover {
  color: #fe8c00;
}
.bei_text {
  font-size: 12px;
  color: #fff;
}
.jin_bei_text {
  display: flex;
  align-items: center;
  color: #fff;
  margin-left: 50px;
}

/* .bottom_box{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jh_box{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 12px;
  color: #999;
}
.jh_box :hover{
  color: #FE8C00;
}
.jh_box img{
  width: 15px;
  height: 15px;
  margin-right: 4px;
}
.bot_nav{
  display: flex;
  align-items: center;
}
.gh_btn{
  cursor: pointer;
  font-size: 12px;
  color: #999;
  display: flex;
  align-items: center;
}
.gh_btn :hover{
  color: #FE8C00;
}
.gh_btn img{
  width: 38px;
  height: 38px;
  margin-right: 4px;
}
.dp_btn{
  cursor: pointer;
  margin: 0 5px;
  width: 109px;
  height: 38px;
  font-size: 12px;
  color: #333;
  background-image: linear-gradient(#F9F8F8, #F1F1F1);
  border: 1px solid #DDDDDD;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lv_btn{
  cursor: pointer;
  width: 107px;
  height: 38px;
  font-size: 12px;
  color: #333;
  background: #EFEFEF;
  border: 1px solid #DDDDDD;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lv_btn_text{
  color: #1A9D3F;
}
.hui_btn_text{
  color: #666666;
}
.a_text{
  color: #999;
}
a:link{
  color: #999;
}
a:HOVER {
    color: #FE8C00;
} */
</style>
